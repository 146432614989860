import React from "react";
import { getHoursRoundLegacy } from "../../helpers/roundUtils";
import AdSense from "react-adsense";
import { AD_CLIENT, AD_SLOT } from "../../constant";
import BaseLayout from "../../layout/BaseLayout/BaseLayout";
import ArchiveListItem from "../../components/ArchiveListItem";
import Pagination from "../../components/Pagination";
import { useStaticQuery, graphql } from "gatsby";

const HistoryLegacyTemplate = ({ pageContext, data: dataGraphql }) => {
  const data = dataGraphql.legacyHistory.data.filteredFromCurrentRound;

  const { id, pagination } = pageContext;

  if (!data[0]) {
    return (
      <>
        <h1>brak danych z tej sesji</h1>
        <Pagination {...pagination} />
      </>
    );
  }

  const date = new Date(data[0].updatedAt).toLocaleDateString("pl-PL");
  //
  const sorrtedArrayByDate = data
    .sort(function (a, b) {
      //@ts-ignore
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .reverse();

  const arrayWithUniqueValue = [
    //@ts-ignore
    ...new Map(
      sorrtedArrayByDate.map(item => [item["productName"], item])
    ).values(),
  ];
  const sorrtedArrayByDateUnique = arrayWithUniqueValue
    .sort(function (a, b) {
      //@ts-ignore
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .reverse();

  return (
    <BaseLayout
      header={`Dane historyczne z dnia: ${date}, sesja sprzedaży ${getHoursRoundLegacy(
        data[0].numOfRound
      )}`}
      description={`Poniżej znajdują się dane historyczne,które zostały zebrane podczas sesji sprzedaży o godz.
      ${getHoursRoundLegacy(data[0].numOfRound)}  na podstawie
    zarejestowanych czasów dostępności, prawidłowość danych jest
    zależna od obciążenia serwerów w danej sesji. Statystyki pozwalają
    ocenić szanse na zakup wybranego produktu w następnych sesjach.
    
    `}
    >
      <Pagination {...pagination} />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow md:rounded-lg">
                <div className="bg-white shadow overflow-hidden sm:rounded-md">
                  <ul role="list" className="divide-y divide-gray-200 ml-0">
                    {sorrtedArrayByDateUnique.map(
                      (item: any, index: React.Key | null | undefined) => (
                        <React.Fragment key={index}>
                          {Boolean(Number(index) % 5 === 0) && index !== 0 && (
                            <AdSense.Google
                              client={AD_CLIENT}
                              slot={AD_SLOT}
                              style={{ display: "block" }}
                              format="auto"
                              responsive="true"
                              layoutKey="-gw-1+2a-9x+5c"
                            />
                          )}
                          <ArchiveListItem filteredArray={data} {...item} />
                        </React.Fragment>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Pagination {...pagination} />
    </BaseLayout>
  );
};

export const query = graphql`
  query HistoryLegacy($id: String) {
    legacyHistory(id: { eq: $id }) {
      id
      url
      data {
        filteredFromCurrentRound {
          updatedAt
          stockStatus
          sortIndex
          productUrl
          productName
          productId
          price
          numOfRound
          mineId
          mine
        }
      }
    }
  }
`;

export default HistoryLegacyTemplate;
